function countComboOptionPrice(optionDetail) {
	let total = 0
	total += optionDetail.optionDetailPrice * optionDetail.count
	return total
}

function countComboPrice(combo) {
	let total = 0
	combo.optionList.forEach(e => {
		getSelectedComboOptionDetail(e, f => (total += countComboOptionPrice(f)))
	})
	return (total + combo.price) * combo.count
}

function countComboListPrice(product) {
	let total = 0
	if (product.comboSelect?.length > 0) {
		product.comboSelect.forEach(e => {
			e.details.forEach(({ detail, optionSelect }) => {
				total += detail.price * detail.count
				total += optionSelect.reduce(
					(c, d) => c + d.reduce((a, b) => a + b.optionDetailSelect.price, 0),
					0,
				)
			})
		})
	}
	return total * product.count
}

function countUpgradeListPrice(product) {
	let total = 0
	if (product.upgradeListId) {
		total += product.upgradePrice
		product.upgradeSelect?.forEach(e => {
			e.upgradeContentDetails.forEach(
				({ upgradeContentDetail, optionSelect }) => {
					total += upgradeContentDetail.price * upgradeContentDetail.count
					total += optionSelect.reduce(
						(c, d) => c + d.reduce((a, b) => a + b.optionDetailSelect.price, 0),
						0,
					)
				},
			)
		})
	}
	return total * product.count
}

function countOptionPrice(product) {
	const { count, optionSelect } = product
	return (
		optionSelect?.reduce(
			(a, b) => a + b.optionDetailSelect.count * b.optionDetailSelect.price,
			0,
		) * count || 0
	)
}

export function countProductPrice(product) {
	const { count, itemPrice, price } = product
	const isOrder = itemPrice == null
	const initialPrice = isOrder ? price : itemPrice
	return (
		countOptionPrice(product) +
		count * initialPrice +
		countUpgradeListPrice(product) +
		countComboListPrice(product)
	)
}

function countOrderComboListPrice(product) {
	let total = 0
	if (product.comboList?.length > 0) {
		product.comboList.forEach(combo => {
			total += combo.price * combo.count
			if (combo.optionList?.length) {
				combo.optionList.forEach(
					option => (total += option.price * option.count),
				)
			}
		})
	}
	return total
}

function countOrderUpgradeListPrice(product) {
	let total = 0
	if (product.upgradeListId) {
		total += product.upgradePrice
		product.upgradeSelect?.forEach(e => {
			total += e.upgradeContentDetail.price * e.upgradeContentDetail.count
			total += e.upgradeContentDetail.optionSelect.reduce(
				(c, d) => c + d.reduce((a, b) => a + b.optionDetailSelect.price, 0),
				0,
			)
		})
	}
	return total
}

const countOrderOptionPrice = product => {
	let total = 0
	product.optionList?.forEach(option => {
		total += option.count * option.price
	})
	return total
}

export function countOrderPrice(product) {
	const { count, itemPrice, price } = product
	const isOrder = itemPrice == null
	const initialPrice = isOrder ? price : itemPrice
	return (
		countOrderOptionPrice(product) +
		count * initialPrice +
		countOrderUpgradeListPrice(product) +
		countOrderComboListPrice(product)
	)
}

export function getOrderTotal(order) {
	return order?.reduce((p, e) => p + countOrderPrice(e), 0) || 0
}

export function getCartTotal(order) {
	return order?.reduce((p, e) => p + countProductPrice(e), 0) || 0
}

// callback return true 表示終止迴圈
function getMultiCountItem(data, itemListKey, itemIdKey, callback) {
	const list = data[itemListKey]
	const countMap = data.count.reduce((p, e) => ((p[Number(e)] = true), p), {})
	let isBreak = false
	for (let i = 0; i < list.length; i++) {
		const e = list[i]
		if (countMap[e[itemIdKey]]) {
			if (callback) {
				isBreak = callback(e) ?? false
			}
		}
		if (isBreak === true) break
	}
}

function getSelectItem(
	data,
	itemListKey,
	itemIdKey,
	isMultiCondition,
	callback,
) {
	const list = data[itemListKey]
	if (isMultiCondition) {
		getMultiCountItem(data, itemListKey, itemIdKey, callback)
	} else {
		const selectId = Number(data.count)
		const selectIndex = list.findIndex(e => e[itemIdKey] === selectId)
		if (selectIndex !== -1) {
			callback && callback(list[selectIndex])
		}
	}
}

export function getSelectedComboItem(data, callback) {
	getSelectItem(data, 'comboItem', 'id', data.chooseType === 1, callback)
}

export function getSelectedMultiComboItem(data, callback) {
	getMultiCountItem(data, 'comboItem', 'id', callback)
}

export function getSelectedComboOptionDetail(data, callback) {
	getSelectItem(
		data,
		'optionDetailList',
		'optionDetailId',
		data.optionType,
		callback,
	)
}

export function getListKeyToObj(data, key) {
	return data.reduce((p, e) => ((p[e[key]] = e), p), {})
}

// 將購物車內容轉變成 新增訂單的 orderDetailList參數
export function getOrderDetailList(cart) {
	let list = []
	cart.forEach(item => {
		list.push({
			itemId: item.itemId,
			count: item.count,
			discountPrice: 0,
			entertain: false,
			remark: item.itemRemark,
			upgradeId: item.upgradeListId,
			optionList: item.optionSelect?.map(option => {
				return {
					optionId: option.id,
					optionDetailId: option.optionDetailSelect.id,
					count: option.optionDetailSelect.count,
				}
			}),
			upgradeList: item.upgradeSelect?.reduce((upgradeList, upgrade) => {
				const upgradeObj = []
				upgrade.upgradeContentDetails.forEach(
					({ upgradeContentDetail, optionSelect }) => {
						for (let i = 0; i < upgradeContentDetail.count; i++) {
							upgradeObj.push({
								contentId: upgrade.upgradeContentId,
								itemId: upgradeContentDetail.itemId,
								optionList: optionSelect[i]?.map(option => {
									return {
										optionId: option.id,
										optionDetailId: option.optionDetailSelect.id,
										count: option.optionDetailSelect.count,
									}
								}),
							})
						}
					},
				)
				return [...upgradeList, ...upgradeObj]
			}, []),
			comboList: item.comboSelect?.reduce((comboList, combo) => {
				const comboObj = []
				combo.details.forEach(({ detail, optionSelect }) => {
					for (let i = 0; i < detail.count; i++) {
						comboObj.push({
							contentId: combo.id,
							itemId: detail.itemId,
							optionList: optionSelect[i]?.map(option => {
								return {
									optionId: option.id,
									optionDetailId: option.optionDetailSelect.id,
									count: option.optionDetailSelect.count,
								}
							}),
						})
					}
				})
				return [...comboList, ...comboObj]
			}, []),
		})
	})
	return list
}

export function isUpgradeContentAllNotSale(upgradeContent) {
	const find = upgradeContent.upgradeContentDetailList.find(
		detail => detail.itemStatus && detail.availableForSale,
	)
	return !find
}

export function isComboContentAllNotSale(comboContent) {
	const find = comboContent.detailList.find(
		detail => detail.itemStatus && detail.availableForSale,
	)
	return !find
}
