<template>
	<div class="odl">
		<div v-if="data.optionSelect && data.optionSelect.length > 0">口味：</div>
		<div
			class="odl__list"
			v-for="(list, index) in data.optionSelect"
			:key="'cartItem' + index"
		>
			<div class="odl__list__name">
				<span>+ {{ list.optionDetailSelect.name }}</span>
				<span>
					{{
						list.optionDetailSelect.price > 0
							? `($${$stepPrice(
									list.optionDetailSelect.price * list.optionDetailSelect.count,
							  )})`
							: ''
					}}
				</span>
			</div>
		</div>

		<div>
			<div v-if="upgrade">
				<div class="odl__combo__li">
					升級： {{ upgrade.name
					}}{{ upgrade.price ? `($${$stepPrice(upgrade.price)})` : '' }}
				</div>
				<div
					class="odl__combo"
					v-for="content in upgrade.contents"
					:key="content.id"
				>
					<div
						v-for="(detail, detailIndex) in content.details"
						:key="detailIndex"
						style="margin-bottom: 5px"
					>
						<div>
							<span>{{ content.name }}</span>
							<span style="margin-left: 5px">
								- {{ detail.itemName }} x{{ detail.count }}
								{{ detail.price ? `($${$stepPrice(detail.price)})` : '' }}
							</span>
						</div>
						<div
							class="options"
							v-for="option in detail.options"
							:key="`${option.id}${option.detail.id}`"
						>
							<div class="options__detail">
								<span>+ {{ option.detail.name }}</span>
								<span>
									{{
										option.detail.price > 0
											? `($${$stepPrice(
													option.detail.price * option.detail.count,
											  )})`
											: ''
									}}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="combos?.length > 0">
				<div class="odl__combo__li" v-for="combo in combos" :key="combo.id">
					<div
						v-for="(detail, detailIndex) in combo.newDetails"
						:key="detailIndex"
						style="margin-bottom: 5px"
					>
						<div>
							<span>{{ combo.name }}</span>
							<span style="margin-left: 5px">
								- {{ detail.itemName }} x{{ detail.count }}
								{{
									detail.price
										? `($${$stepPrice(detail.price * detail.count)})`
										: ''
								}}
							</span>
						</div>
						<div
							class="options"
							v-for="option in detail.options"
							:key="`${option.id}${option.detail.id}`"
						>
							<div class="options__detail">
								<span>+ {{ option.detail.name }}</span>
								<span>
									{{
										option.detail.price > 0
											? `($${$stepPrice(
													option.detail.price * option.detail.count,
											  )})`
											: ''
									}}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<span class="odl__remark" v-if="data.itemRemark">{{
			data.itemRemark
		}}</span>
	</div>
</template>

<script>
export default {
	name: 'OrderList',
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			upgrade: null,
			combos: [],
		}
	},
	computed: {},
	watch: {
		data(newData, oldData) {
			this.regroupUpgrades()
			this.regroupCombos()
		},
	},
	created() {
		this.regroupUpgrades()
		this.regroupCombos()
	},
	methods: {
		createNewOptionDetail(selectedOptionDetail) {
			return {
				id: selectedOptionDetail.id,
				name: selectedOptionDetail.name,
				price: selectedOptionDetail.price,
				count: selectedOptionDetail.count,
			}
		},
		createNewDetails(detail, optionSelect) {
			let selectedOptionMap = new Map()

			optionSelect.forEach(selectedOptions => {
				let key = ''
				let newOptions = []

				selectedOptions
					.sort(
						(optionA, optionB) =>
							optionA.optionDetailSelect.id - optionB.optionDetailSelect.id,
					) // 調整順序，確保key值組合是一樣的
					.forEach(currentOption => {
						key +=
							String(currentOption.id) +
							String(currentOption.optionDetailSelect.id)

						newOptions.push({
							id: currentOption.id,
							name: currentOption.name,
							detail: this.createNewOptionDetail(
								currentOption.optionDetailSelect,
							),
						})
					})

				if (selectedOptionMap.has(key)) {
					selectedOptionMap.get(key).count += 1
				} else {
					const value = {
						count: 1,
						options: newOptions,
					}
					selectedOptionMap.set(key, value)
				}
			})

			const newDetails = []
			for (let [key, value] of selectedOptionMap) {
				newDetails.push({
					itemId: detail.itemId,
					itemName: detail.itemName,
					price: detail.price,
					count: value.count,
					options: value.options,
				})
			}
			return newDetails
		},
		regroupCombos() {
			this.combos = this.data.comboSelect?.map(combo => {
				let newDetails = []

				combo.details.forEach(({ detail, optionSelect }) => {
					const currentDetails = this.createNewDetails(detail, optionSelect)
					newDetails = [...newDetails, ...currentDetails]
				})

				return {
					id: combo.id,
					name: combo.name,
					newDetails: newDetails,
				}
			})
		},
		regroupUpgrades() {
			if (!this.data.upgradeSelect || this.data.upgradeSelect.length === 0) {
				return
			}

			const newContents = this.data.upgradeSelect.map(currentUpgrade => {
				let newDetails = []

				currentUpgrade.upgradeContentDetails.forEach(
					({ upgradeContentDetail, optionSelect }) => {
						const currentDetails = this.createNewDetails(
							upgradeContentDetail,
							optionSelect,
						)
						newDetails = [...newDetails, ...currentDetails]
					},
				)

				return {
					id: currentUpgrade.id,
					name: currentUpgrade.name,
					details: newDetails,
				}
			})

			this.upgrade = {
				name: this.data.upgradeName,
				price: this.data.upgradePrice,
				contents: newContents,
			}
		},
	},
}
</script>

<style lang="sass" scoped>
.odl
  flex-basis: 100%
  color: #8E8E8E
  font-size: 14px
  line-height: 20px
  margin: 4px 0 0
  &__combo
    padding-left: 12px
    &__li
    &__item
      padding-left: 12px
  &__list
    display: flex
    padding-left: 12px
    &__name
      flex: 1
  &__remark
    font-size: 13px
    line-height: 18px
    border: 1px solid #BEBEBE
    padding: 2.5px 15.5px 2.5px 11px
    color: #8E8E8E
    border-radius: 2.5px
    margin-top: 8px
    display: block
    width: fit-content
  .options__detail
    padding-left: 12px
</style>
