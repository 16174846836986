import Vue from 'vue'
import VueRouter from 'vue-router'
import Buy from '@/views/buy/index.vue'
import User from '@/views/user/index.vue'

Vue.use(VueRouter)

const routes = [
	// {
	// 	path: '/',
	// 	redirect: '/Store',
	// 	meta: { requiredStore: true },
	// },
	// {
	//   path: '/register',
	//   name: 'Register',
	//   component: () => import('../views/login/Register.vue')
	// },
	// {
	//   path: '/login',
	//   name: 'Login',
	//   component: () => import('../views/login/Login.vue')
	// },
	// {
	// 	path: '/Verify',
	// 	name: 'Verify',
	// 	component: () => import('../views/login/VerifyCaptcha.vue'),
	// },
	// {
	//   path: '/Forget',
	//   name: 'Forget',
	//   component: () => import('@/views/login/ForgetPassword.vue')
	// },
	// {
	//   path: '/Reset*',
	//   name: 'Reset',
	//   component: () => import('@/views/login/ResetPassword.vue')
	// },
	{
		path: '/:random',
		name: 'Main',
		meta: { requiredStore: false },
		// redirect: '/Store',
		component: Buy,
		children: [
			{
				path: 'Order',
				name: 'Order',
				meta: { requiredStore: true },
				component: () => import('@/views/buy/Order.vue'),
			},
			{
				path: 'Store',
				name: 'Store',
				meta: { requiredStore: true, title: '點溡' },
				component: () => import('@/views/buy/Store.vue'),
			},
			{
				path: 'Detail/:id*',
				name: 'Detail',
				meta: { requiredStore: true },
				component: () => import('@/views/buy/Detail/Detail.vue'),
			},
			{
				path: 'Cart',
				name: 'Cart',
				meta: { requiredStore: true },
				component: () => import('@/views/buy/Cart.vue'),
			},
			{
				path: 'TakeMeal/:OrderId',
				name: 'TakeMeal',
				meta: { requiredStore: true },
				component: () => import('@/views/buy/TakeMeal.vue'),
			},
			{
				path: 'OrderConfirm',
				name: 'OrderConfirm',
				meta: { requiredStore: true },
				component: () => import('@/views/buy/OrderConfirm.vue'),
			},
			{
				path: 'OrderCancel',
				name: 'OrderCancel',
				meta: { requiredStore: true },
				component: () => import('@/views/buy/OrderCancel.vue'),
			},
		],
	},
	{
		path: '/:random/Contact',
		name: 'Contact',
		component: () => import('@/views/information/Contact.vue'),
	},
	{
		path: '/:random/Privacy',
		name: 'Privacy',
		component: () => import('@/views/information/Privacy.vue'),
	},
	{
		path: '/:random/Service',
		name: 'Service',
		component: () => import('@/views/information/Service.vue'),
	},
	{
		path: '/:random/About',
		name: 'About',
		component: () => import('@/views/information/About.vue'),
	},
	{
		path: '/:random/QRCode*',
		name: 'QRCode',
		component: () => import('@/views/store/QRCode.vue'),
	},
	{
		path: 'Disappears',
		name: 'Disappears',
		component: () => import('@/views/store/Disappears.vue'),
	},
	{
		path: '/:random/Refresh',
		name: 'Refresh',
		component: () => import('@/views/store/Refresh.vue'),
	},
	{
		path: '/:random',
		name: 'User',
		component: User,
		children: [
			// {
			// 	path: 'MyAccount',
			// 	name: 'MyAccount',
			// 	component: () => import('@/views/user/MyAccount.vue'),
			// },
			{
				path: 'MyOrder',
				name: 'MyOrder',
				meta: { requiredStore: true },
				component: () => import('@/views/user/MyOrder'),
			},
		],
	},
	{
		path: '*',
		component: () => import('@/views/notFound'),
	},
]

const router = new VueRouter({ routes, mode: 'history' })

export default router
