<template>
	<div class="cancel">
		<ByIcon
			icon="cancel"
			class="icon"
			size="18"
			@click.native="closeWindow"
		></ByIcon>
	</div>
</template>

<script>
export default {
	name: 'ByCancel',
	methods: {
		closeWindow() {
			this.$emit('close')
		},
	},
}
</script>

<style lang="sass" scoped>
.cancel
  display: flex
  justify-content: flex-end
  position: relative
  z-index: 2
  height: 45px
  align-items: center
  .icon
    margin-right: 15px
</style>
