import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import login from './modules/login'
import detail from './modules/detail'
import register from './modules/register'
import myOrder from './modules/myOrder'
import socket from './modules/socket'
import kiosk from './modules/kiosk'
import globalStore from './modules/global'
import VuexPersistence from 'vuex-persist'
import takeMeal from '@/store/modules/takeMeal'
import storeModule from './modules/store'

const vxLocalStorage = new VuexPersistence({
	storage: window.localStorage,
	modules: ['user'],
})
const vxSessionStorage = new VuexPersistence({
	storage: window.sessionStorage,
	modules: ['kiosk'],
})
Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		user,
		login,
		register,
		detail,
		myOrder,
		socket,
		takeMeal,
		kiosk,
		store: storeModule,
	},
	getters,
	...globalStore,
	plugins: [vxLocalStorage.plugin, vxSessionStorage.plugin],
})

export default store
