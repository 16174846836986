import request from './axios'
export default {
	getQRcodeHash(params) {
		return request.get(`qrcode/getQRHash`, { params })
	},
	getStore(params) {
		return request.get(`/front/qrcode/getStore`, { params })
	},
	// 取得店家合約狀態
	getStoreContractStatus(params) {
		return request.get(`contract/status`, { params })
	},
}
