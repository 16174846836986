export default {
	namespaced: true,
	state: {
		googleToken: '',
		speedLoginPhoneNumber: '',
		facebookUserId: '',
		facebookUserName: '',
		facebookUserEmail: '',
	},
	mutations: {
		SET_GOOGLE_TOKEN(state, val) {
			state.googleToken = val
		},
		SET_SPEED_LOGIN_PHONE(state, val) {
			state.speedLoginPhoneNumber = val
		},
		SET_FB_USERID(state, val) {
			state.facebookUserId = val
		},
		SET_FB_USERNAME(state, val) {
			state.facebookUserName = val
		},
		SET_FB_USEREMAIL(state, val) {
			state.facebookUserEmail = val
		},
	},
}
