<template>
	<div class="flex">
		<div class="fullscreen-icon" v-if="isKiosk" @click="onToggleFullscreen">
			<img v-if="!!isFullscreen" src="../../assets/fullscreen.png" />
			<img v-if="!isFullscreen" src="../../assets/maximize.png" />
		</div>

		<!--    <div-->
		<!--        class="fullscreen"-->
		<!--        @click="onToggleFullscreen"-->
		<!--        v-if="isKiosk"-->
		<!--    >-->
		<!--      <div>123</div>-->
		<!--    </div>-->
		<router-link
			class="go-store"
			v-if="href !== `/${random}/Store` && !isKiosk"
			:to="`/${random}/Store`"
			>回菜單</router-link
		>
		<div class="href" v-if="href === `/${random}/Store` || isKiosk" />
		<router-link class="store" to="Store">{{ currentPage }}</router-link>
		<div class="tools">
			<div
				class="cart"
				v-if="href !== `/${random}/Cart` && canBuy && !isKiosk"
				@click="goCart"
			>
				<ByIcon icon="cart" class="icon" size="30" />
				<div class="cart-quantity" v-show="productLength">
					{{ productLength }}
				</div>
			</div>
			<div v-if="href === `/${random}/Cart` || isKiosk" class="cart"></div>
			<!--      <ByIcon-->
			<!--        icon="male"-->
			<!--        class="icon"-->
			<!--        size="30"-->
			<!--        @click.stop.native="handleSwitchUserDrop"-->
			<!--      />-->
			<ByIcon
				v-if="href !== `/${random}/MyOrder` && !isKiosk"
				icon="clipboard-list-outline"
				class="icon"
				size="30"
				@click.native="onGoMyOrder"
			/>
			<ByIcon
				v-if="isKiosk"
				icon="logout"
				type="png"
				class="icon"
				size="30"
				@click.native="logout(true)"
			/>
			<div v-if="href === `/${random}/MyOrder` && !isKiosk" class="cart"></div>
		</div>
		<ByLogoutConfirm
			v-model="isShowLogoutConfirm"
			v-if="isKiosk"
			@update="v => logout(v)"
		/>
		<!--    <UserDrop v-model="isShow" @onOutside="handleClickOutsideSwitchUserDrop" />-->
	</div>
</template>
<script>
// import UserDropdown from './UserDropdown.vue'
import toggleFullscreen from '@/utils/toggleFullscreen'

export default {
	components: {
		// UserDrop: UserDropdown
	},
	computed: {
		currentPage() {
			let pageName = this.$route.name
			switch (pageName) {
				case 'Cart':
				case 'Order':
					return '購物車'
				case 'TakeMeal':
					return '取餐詳情'
				case 'MyAccount':
					return '我的帳號'
				case 'MyOrder':
					return '我的訂單'
				default:
					return this.$store.getters.storeName
			}
		},
		isGuest() {
			return this.$store.getters.isGuest
		},
		isKiosk() {
			return this.$store.getters.isKiosk
		},
		productLength() {
			return this.$store.getters['user/productLength']
		},
		canBuy() {
			return this.$store.getters.canBuy
		},
		random() {
			return this.$route.params.random
		},
		href() {
			return this.$route.path
		},
	},
	data() {
		return {
			img1Path: '../../assets/cart.svg',
			isShow: false,
			isShowLogoutConfirm: false,
			isFullscreen: false,
		}
	},
	mounted() {
		this.isFullscreen = document.fullscreenElement
		document.addEventListener('fullscreenchange', this.onFullscreenChange)
	},
	beforeDestroy() {
		document.removeEventListener('fullscreenchange', this.onFullscreenChange)
	},
	methods: {
		goCart() {
			// if (this.isKiosk) {
			//   return
			// }
			this.$router.push(`/${this.random}/Cart`)
		},
		onGoMyOrder() {
			this.$router.push(`/${this.random}/MyOrder`)
		},
		logout(val) {
			this.isShowLogoutConfirm = val
		},
		onToggleFullscreen() {
			toggleFullscreen()
		},
		onFullscreenChange() {
			this.isFullscreen = document.fullscreenElement
		},
	},
}
</script>

<style lang="sass" scoped>
%reset-link
  text-decoration: none
.flex
  position: fixed
  top: 0
  display: flex
  width: 100%
  height: 50px
  background: #fff
  justify-content: space-between
  align-items: center
  border-bottom: 1px solid #D8D8D8
  z-index: 1000
  .href
    @extend %reset-link
    margin-left: 18px
    width: 66px



  .fullscreen-icon
    height: 28px
    width: 28px
    margin-left: 18px

  .go-store
    @extend %reset-link
    margin-left: 18px
    color: #fff
    background: #432D2C
    border-radius: 50px
    padding: 6px 12px
    font-size: 14px
  .back
    width: 80px
    height: 30px
    +fc
    font-size: 153px
    padding: 5px 10px
    margin: 10px
    border: 1px solid #666
    border-radius: 25px
    color: #fff
    background: #432D2C
  .store
    @extend %reset-link
    color: #000
    flex: 1
    +fc
    font-size: 20px
    font-weight: bold
    padding: 10px
  .tools
    +fc
    justify-content: flex-end
    margin-right: 18px
    > .icon
      margin-left: 12px
  .cart
    position: relative
    width: 30px
    height: 30px
    .icon
      height: 100%
    &-quantity
      position: absolute
      top: -2px
      right: -2px
      border-radius: 50%
      width: 15px
      height: 15px
      background: #f00
      user-select: none
      &:before
        content: ''
        display: block
        position: absolute
        width: 15px
        height: 15px
        background: transparent
        border-radius: 50%
      +fc
      font-size: 13px
      color: #fff

img
  width: 100%
  height: 100%
</style>
