export default {
	namespaced: true,
	state: {
		registerPassword: '',
		registerName: '',
		registerEmail: '',
		registerPhone: '',
		form: {
			name: '',
			account: '',
			email: '',
			password: '',
			confirmPassword: '',
		},
		passwordStrength: false,
	},
	mutations: {
		SET_REGISTER_PASSWORD($, val) {
			$.registerPassword = val
		},
		SET_REGISTER_NAME($, val) {
			$.registerName = val
		},
		SET_REGISTER_EMAIL($, val) {
			$.registerEmail = val
		},
		SET_REGISTER_PHONE($, val) {
			$.registerPhone = val
		},
		CLEAR_FORM($) {
			$.form = {
				name: '',
				account: '',
				email: '',
				password: '',
				confirmPassword: '',
			}
		},
		SET_PASSWORD_STRENGTH($, val) {
			$.passwordStrength = val
		},
	},
}
