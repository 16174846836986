<template>
	<div :class="'loader ' + 'loader--' + size">
		<div
			:class="'loader__circle ' + 'loader__circle--' + size"
			v-for="e in 3"
			:key="e"
			:style="{ backgroundColor: color }"
		></div>
	</div>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'ByLoader',
	// components: { COMPONENT_NAME },
	props: {
		color: {
			required: false,
			type: String,
			default: '#432D2C',
		},
		size: {
			required: false,
			type: String,
			default: 'md',
		},
	},
	// data() {return {}},
	// computed: {},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	// methods: {},
}
</script>
<style lang="sass" scoped>
.loader
  display: flex
  justify-content: space-between
  &--lg
    width: 50px
  &--md
    width: 40px
  &--sm
    width: 30px
  &__circle
    background-color: #432D2C
    border-radius: 50%
    animation: loader 1s infinite
    &:nth-child(2)
      animation-delay: 0.1s
    &:nth-child(3)
      animation-delay: 0.2s
    &--lg
      width: 11px
      height: 11px
    &--md
      width: 8px
      height: 8px
    &--sm
      width: 5px
      height: 5px

@keyframes loader
  0%
    transform: scale(0.1)
  100%
    transform: scale(1)
</style>
