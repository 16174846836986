<template>
	<div class="by-radio">
		<label :style="{ opacity: disabled ? 0.5 : 1 }">
			<input
				:class="`radios${name}`"
				type="radio"
				ref="radio"
				:value="name"
				@change="handleChange"
				v-model="model"
				@click="onClick"
				:disabled="disabled"
			/>
			<div class="radio"></div>
			<span class="title">{{ title }}</span>
		</label>
		<slot></slot>
	</div>
</template>
<script>
export default {
	name: 'ByRadio',
	props: {
		name: String,
		title: String,
		value: String,
		required: Boolean,
		cantToggle: Boolean,
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		model: {
			set(val) {
				this.$emit('input', val)
			},
			get() {
				return this.value
			},
		},
	},
	methods: {
		onClick() {
			const store = this.model
			setTimeout(() => {
				if (!this.required && store === this.model && !this.cantToggle) {
					document.getElementsByClassName(
						`radios${this.model}`,
					)[0].checked = false
					this.$emit('input', '')
				}
			})
		},
		handleChange() {
			this.$nextTick(() => {
				this.$emit('change', this.model)
			})
		},
	},
}
</script>

<style lang="sass" scoped>
.by-radio
  display: flex
  align-items: center
  justify-content: space-between
  label
    display: flex
    align-items: center
    flex: 1
  input
    display: none
  .radio
    min-width: 27px
    width: 27px
    height: 27px
    border: 1px solid #8e8e8e
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center
  input[type="radio"]:checked
    &+.radio
      &:before
        width: 17px
        height: 17px
        background: #294B70
        content: ''
        border-radius: 50%
  .title
    margin-left: 10px
</style>
