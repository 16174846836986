<template>
	<form action="javascript:return true">
		<label class="by-input" :class="{ [type]: true }">
			<div class="title"><i v-if="required">*</i>{{ title }}</div>

			<span class="icon">
				<input
					ref="inputRef"
					v-if="type !== 'textarea' && !disabled"
					:class="{ [variant]: true }"
					:value="value"
					@input="back($event.target.value)"
					@keydown="search"
					@focus="focus"
					:type="inputType"
					:placeholder="placeholder"
					:maxlength="max"
					:inputMode="inputMode"
				/>
				<div v-if="type !== 'textarea' && disabled" class="fake-input">
					{{ value }}
				</div>
				<textarea
					ref="inputRef"
					v-if="type === 'textarea'"
					:class="variant"
					:value="value"
					:rows="rows"
					@focus="focus"
					:placeholder="placeholder"
					@input="back($event.target.value)"
				></textarea>
				<i v-if="type == 'search'" class="icon__pic" @click="search(true)"
					><img :src="searchIcon"
				/></i>
			</span>
		</label>
	</form>
</template>
<script>
import searchIcon from '../assets/search.svg'

export default {
	name: 'ByInput',
	props: {
		type: {
			default: 'text',
			type: String,
		},
		inputType: {
			default: 'text',
			type: String,
		},
		value: {
			default: '',
			type: String,
		},
		placeholder: {
			default: '',
			type: String,
		},
		title: {
			default: '',
			type: String,
		},
		required: {
			default: false,
			type: Boolean,
		},
		rows: {
			default: 1,
			type: Number,
		},
		variant: {
			required: false,
			type: String,
			default: 'inputBasic',
		},
		disabled: {
			required: false,
			type: Boolean,
		},
		max: {
			required: false,
			type: Number,
			default: null,
		},
		inputMode: {
			required: false,
			type: String,
		},
	},
	data() {
		return {
			searchIcon,
		}
	},
	watch: {},
	methods: {
		back(val) {
			if (!this.disabled) {
				this.$emit('input', val)
			}
		},
		search(e) {
			if (e.key === 'Enter' || e === true) {
				this.$emit('search', this.value)
			}
		},
		blur() {
			if (this.$refs.inputRef) {
				setTimeout(() => this.$refs.inputRef.blur())
			}
		},
		focus(e) {
			this.$emit('focus', e)
		},
	},
}
</script>

<style lang="sass" scoped>
.fake-input
  font-size: 15px
  color: #B5B5B5
  text-align: right
  width: 100%
  height: 40px
  border-radius: 50px
  background: #f3f3f3
  display: flex
  align-items: center
  justify-content: flex-end
  padding-right: 15px
.by-input
  position: relative
  width: 100%
  font-size: 15px
  .title
    padding-left: 15px
    position: absolute
    top: 50%
    transform: translateY(-50%)
  .inputBasic
    width: 100%
    height: 40px
    border-radius: 50px
    background: #f3f3f3
    font-size: 15px
    &::placeholder
      font-size: 15px
      color: #B5B5B5
      width: 100%

  .input-middle
    padding-left: 75px

  .input-right
    padding-left: 130px
    text-align: right

  .input-outline
    border: 1px solid #D8D8D899
    border-radius: 5px
    width: 70px
    height: 27px
    margin-left: 10px
    font-size: 15px

  i
    display: inline
    color: #ff0000
    margin-right: 3px
  &.search
    .icon
      position: relative
      &__pic
        display: inline-block
        width: 20px
        height: 20px
        position: absolute
        top: 50%
        transform: translateY(-50%)
        right: 20px
        cursor: pointer
    input
      border-radius: 50px
      background: #f3f3f3
      border: 0

input
  box-sizing: border-box
  border: 0
  padding: 15px
input:focus
  outline: 0
.textareaBasic
  width: 100%
  height: 110px
  border-radius: 10px
  resize: none
  background: #f3f3f3
  border: 0
  padding: 14px
  font-size: 15px
  color: #434343
  &:focus
    outline: initial
.textarea-middle
  padding: 15px 15px 15px 130px
</style>
