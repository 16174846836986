<template>
	<div v-if="dialog" class="modal-overlay">
		<div class="modal-container">
			<img
				src="../assets/maintenance.webp"
				alt="维护中"
				class="maintenance-image"
			/>
			<div class="message">
				<div class="message-title">
					{{ title }}
				</div>
				<div class="message-content">
					{{ message }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MyMaintenanceModal',
	props: {
		refreshRate: {
			type: Number,
			default: 60000,
		},
	},
	data() {
		return {
			dialog: false,
			timer: null, // 計時器
		}
	},
	watch: {
		'$store.state.maintenance.modal'() {
			const newValue = this.$store.state.maintenance.modal
			if (newValue) {
				this.startTimer()
				this.dialog = true
			} else {
				this.clearTimer()
				location.reload()
			}
		},
	},
	computed: {
		title() {
			const title = this.$store.state.maintenance.title
			return title ? title : ''
		},
		message() {
			const message = this.$store.state.maintenance.message
			return message ? message : ''
		},
	},
	methods: {
		startTimer() {
			this.clearTimer()
			this.timer = setTimeout(() => {
				this.$api.getUrl().then(res => {
					this.$store.commit('setMaintenance', {
						modal: false,
						title: '',
						message: '',
					})
					location.reload()
				})
			}, this.refreshRate)
		},
		clearTimer() {
			if (this.timer) {
				clearTimeout(this.timer)
				this.timer = null
			}
		},
	},
	beforeDestroy() {
		this.clearTimer()
		this.dialog = false
	},
}
</script>

<style scoped>
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;

	/* 防止滾動 */
	overflow: hidden;
	text-align: center;
	/* 不開放選取 */
	user-select: none; /* 標準語法 */
	-webkit-user-select: none; /* Safari 3.1+ */
	-moz-user-select: none; /* Firefox 2+ */
	-ms-user-select: none; /* IE 10+ */
}

.modal-container {
	background-color: #cfd9e5;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	padding: 20px;
	text-align: center;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.maintenance-image {
	display: block;
}

.message {
	color: white;
	margin-top: 20px;
}

.message-title {
	font-size: 2.3rem;
	font-weight: bold;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.message-content {
	margin-top: 10px;
	font-size: 2rem;
	font-weight: bold;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
	opacity: 0;
	animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
/* 對於寬度小於或等於 500px 的裝置 */
@media (max-width: 500px) {
	.maintenance-image {
		max-width: 80%;
		height: auto;
	}

	.message-title {
		padding: 0 10px;
		font-size: 2rem;
		text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
	}

	.message-content {
		padding: 0 10px;
		font-size: 1.5rem;
		text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
		max-width: 300px;
		word-wrap: break-word;
		overflow-wrap: break-word;
	}
}

/* 對於寬度在 501px 到 700px 之間的裝置 */
@media (min-width: 501px) and (max-width: 700px) {
	.maintenance-image {
		max-width: 70%;
		height: auto;
	}
}

/* 對於寬度在 701px 到 1001px 之間的裝置 */
@media (min-width: 701px) and (max-width: 1000px) {
	@media (max-height: 600px) {
		.maintenance-image {
			max-width: 50%;
			height: auto;
		}
	}

	@media (min-height: 601px) {
		.maintenance-image {
			max-width: 550px;
			height: auto;
		}
	}
}

/* 對於寬度大於 1001px 的裝置 */
@media (min-width: 1001px) {
	@media (max-height: 650px) {
		.maintenance-image {
			max-height: 60%;
			width: auto;
		}
	}

	@media (min-height: 651px) {
		.maintenance-image {
			max-height: 40%;
			width: auto;
		}
	}
}

/* 對於高度小於或等於 500px 的裝置 */
@media (max-height: 500px) {
	.message {
		padding: 5px;
	}

	.message-title {
		font-size: 2rem;
	}

	.message-content {
		font-size: 1.5rem;
	}

	.maintenance-image {
		max-height: 80%;
		width: auto;
	}
}
</style>
