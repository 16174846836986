import axios from 'axios'
import router from '@/router/index'
import store from '@/store/index'

const set = axios.create({
	baseURL: `${process.env.VUE_APP_BASE_API}/api`,
	timeout: 60000,
})

set.interceptors.request.use(
	config => {
		const vuex = store.state
		const token = vuex.user.xsrfToken
		if (token !== '') {
			config.headers['Content-Type'] = 'application/json'
			config.headers['X-CSRF-TOKEN'] = token
		}
		config.headers['X-Front-Stage-Version'] = store.state.version
		return config
	},
	error => {
		return Promise.reject(error)
	},
)

set.interceptors.response.use(
	response => {
		return response.data
	},
	error => {
		if (error.message === 'Network Error' || error.code === 'ECONNABORTED') {
			return Promise.reject('網絡問題，請檢查您的網絡連接')
		}
		const res = error.response
		if (!res) {
			return Promise.reject('無法獲取伺服器回應')
		}
		if (error.response?.config?.url === '/front/login') {
			return Promise.reject(
				res.data.message ? res.data.message : '服務異常，請聯繫相關人員',
			)
		}
		if (res.status === 503) {
			const { title, message } = res.data.data
			store.commit(
				'setMaintenance',
				{
					modal: true,
					title: title,
					message: message,
				},
				{ root: true },
			)
			return Promise.reject('服務更新中')
		}
		if (res.status === 401) {
			// 未登入狀態
			store.commit('user/SET_TOKEN', '')
			store.commit('user/SET_USER_ID', null)
			store.commit('user/SET_GUEST_ID', null)
			store.commit('user/SET_XSRF_TOKEN', '')

			return store.dispatch('guidePage').then(() => {
				return Promise.reject(res.data.message)
			})
		}
		if (res.status === 403) {
			if (res.data.errorCode === 'FORBIDDEN') {
				// csrf 錯誤
				store.commit('user/SET_XSRF_TOKEN', '')

				return store.dispatch('guidePage').then(() => {
					return Promise.reject(res.data.message)
				})
			} else {
				// 店家合約已到期、合約已到期或已經被刪除、店家已刪除
				return router.replace('/null/Error')
			}
		}
		if (error.response?.config?.url === '/front/order/add') {
			return Promise.reject(res.data)
		}

		return Promise.reject(
			res.data.message ? res.data.message : '服務異常，請聯繫相關人員',
		)
	},
)

export default set
