function processOptionList(options) {
	let name = ''
	options
		?.sort((a, b) => a.id - b.id)
		.forEach(option => {
			name += String(option.id)
			if (option.type) {
				name += option.count.join()
				option.count
					?.sort((a, b) => a - b)
					.forEach(optionDetailId => {
						const sameDetail = option.optionDetailList.find(
							detail => String(detail.id) === optionDetailId,
						)
						if (sameDetail) {
							name += String(sameDetail.id)
							name += String(sameDetail.price)
							name += String(sameDetail.count)
						}
					})
			} else {
				name += option.count
				const sameDetail = option.optionDetailList.find(
					detail => String(detail.id) === option.count,
				)
				if (sameDetail) {
					name += String(sameDetail.id)
					name += String(sameDetail.price)
					name += String(sameDetail.count)
				}
			}
		})
	return name
}

function processUpgradeSelect(upgradeListId, upgradeSelect) {
	let name = ''
	upgradeSelect
		.sort((a, b) => a.upgradeContentId - b.upgradeContentId)
		?.forEach(upgrade => {
			name += String(upgrade.upgradeContentId)
			name += String(
				upgrade.select.sort((a, b) => a - b).reduce((a, b) => a + b, ''),
			)

			upgrade.upgradeContentDetails.forEach(({ optionSelect }) => {
				name = processSelectedOptionDetail(optionSelect, name)
			})
		})
	return upgradeListId + name
}

function processComboSelect(comboSelect) {
	let name = ''
	comboSelect
		.sort((a, b) => a.id - b.id)
		?.forEach(currentCombo => {
			name += String(currentCombo.id)
			name += String(
				currentCombo.select.sort((a, b) => a - b).reduce((a, b) => a + b, ''),
			)

			currentCombo.details.forEach(({ optionSelect }) => {
				name = processSelectedOptionDetail(optionSelect, name)
			})
		})
	return name
}

function processSelectedOptionDetail(optionSelect, name) {
	optionSelect.forEach(currentOptions => {
		currentOptions.forEach(currentOption => {
			name += String(currentOption.id)

			if (currentOption.type) {
				name += currentOption.count.join()

				currentOption.count
					?.sort((a, b) => a - b)
					.forEach(currentOptionDetailId => {
						const same = currentOption.optionDetailList.find(
							currentOptionDetail =>
								String(currentOptionDetail.id) === currentOptionDetailId,
						)
						if (same) {
							name += String(same.id)
							name += String(same.price)
							name += String(same.count)
						}
					})
			} else {
				name += currentOption.count
				const same = currentOption.optionDetailList.find(
					currentOptionDetail =>
						String(currentOptionDetail.id) === currentOption.count,
				)
				if (same) {
					name += String(same.id)
					name += String(same.price)
					name += String(same.count)
				}
			}
		})
	})
	return name
}

export function getTotalName(item) {
	let name = ''

	name += String(item.itemPrice)

	// 檢查口味
	name += processOptionList(item.optionList)

	// 檢查升級
	if (item.upgradeListId) {
		name += processUpgradeSelect(item.upgradeListId, item.upgradeSelect)
	}

	// 檢查套餐
	if (item.comboSelect?.length > 0) {
		name += processComboSelect(item.comboSelect)
	}

	return name
}
