<template>
	<div>
		<ToolBar :class="currentPath" />
		<div class="main-page" v-if="!$store.state.maintenance.modal">
			<router-view />
		</div>
		<MyMaintenanceModal />
	</div>
</template>

<script>
import ToolBar from '../layout/ToolBar.vue'
import MyMaintenanceModal from '@/components/MaintenanceModal.vue'
export default {
	components: { MyMaintenanceModal, ToolBar },
	computed: {
		currentPath() {
			let pathName = this.$route.name
			switch (pathName) {
				case 'Store':
					return ''
					break
				default:
					return 'addShadow'
					break
			}
		},
	},

	mounted() {},
	// beforeDestroy() {},
	methods: {},
}
</script>

<style lang="sass" scoped>
.main-page
  margin-top: 50px

.addShadow
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16)
</style>
